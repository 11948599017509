<template>
  <div>
      <lj-nav></lj-nav>
      <router-view/>
      <lj-footer></lj-footer>
  </div>
</template>

<script> 
import LjFooter from '../../../components/footer.vue'
import LjNav from '../../../components/nav.vue'
export default {
  data: function () {
    return {
    }
  },
  methods: {
    onDisagreeBtnClick: function () {
      this.$router.back()
    },
  },
  created: function () {
  },
  components: {
    LjFooter,
    LjNav
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/components/base.min.css');
@import url('./../../../Public/resources/css/components/icon.min.css');
@import url('./../../../Public/resources/css/components/modal.min.css');
@import url('./../../../Public/resources/css/components/nav.min.css');
@import url('./../../../Public/resources/css/components/footer.min.css');
@import url('./../../../Public/utils/element-ui/index.css');
@import url('./../../../Public/resources/css/components/button.min.css');
@import url('./../../../Public/resources/css/components/table.min.css');
@import url('./../../../Public/resources/css/components/utility.min.css');
@import url('./../../../Public/resources/css/templates/home/test/index.min.css');
@import url('./../../../Public/resources/css/components/alert.min.css');
@import url('./../../../Public/resources/css/components/form.min.css');
@import url('./../../../Public/resources/css/components/close.min.css');

.main {
  background: #cfd1cc;
}
</style>
